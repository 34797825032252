<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>UniqueIDs</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ UniqueIDs
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="createUniqueNumber()" class="btn btn-primary text-white">
                    <i class="fa fa-plus font-white"></i>
                      Create UID
                  </v-btn>&nbsp;
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="UniqueID" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="First name" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-text-field label="Surname" outlined dense></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-btn class="btn btn-primary text-white float-right" dark medium>
                    <v-icon small  elevation="2"
                            outlined>fas fa-search</v-icon>&nbsp;
                     Search
                  </v-btn>
                </v-col>
              </div>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                <tr class="text-center">
                  <th>UniqueID</th>
                  <th>First name</th>
                  <th>Surname</th>
                  <th>Date of birth</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr class="text-center">
                    <td>
                      <span href="#" class="">45852663332</span>
                    </td>

                    <td>
                      <span href="#" class="">
                        Saurav
                      </span>
                    </td>

                    <td>
                      <span href="#" class="">Shrestha</span>
                    </td>

                    <td>
                      <span href="#" class=""> 1996-08-03</span>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editUniqueNumber()" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit Unique Number</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td>
                      <span href="#" class="">879823409234</span>
                    </td>

                    <td>
                      <span href="#" class="">
                        Basanta
                      </span>
                    </td>

                    <td>
                      <span href="#" class="">Paul</span>
                    </td>

                    <td>
                      <span href="#" class=""> 1997-08-03</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editUniqueNumber()" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit Unique Number</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr class="text-center">
                    <td>
                      <span href="#" class="">85243234234</span>
                    </td>

                    <td>
                      <span href="#" class="">
                        Ram
                      </span>
                    </td>

                    <td>
                      <span href="#" class="">Christ</span>
                    </td>

                    <td>
                      <span href="#" class=""> 1992-08-03</span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="editUniqueNumber()" class="navi-link">
                                <span class="navi-icon">
                                   <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit Unique Number</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>

                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="dialog"
        persistent
        height="500px"
        max-width="800px">
      <form enctype="multipart/form-data">
        <v-card>
          <v-card-title>
            <span >UID profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                      label="First name*"
                      outlined
                      dense
                      v-model="ameb_unique_number.first_name">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                      label="Middle name*"
                      outlined
                      dense
                      v-model="ameb_unique_number.middle_name">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                      label="Surname*"
                      outlined
                      dense
                      v-model="ameb_unique_number.last_name">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      type="date"
                      label="Date of birth*"
                      outlined
                      dense
                      v-model="ameb_unique_number.dob">
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-select
                      label="Gender*"
                      :items="gender"
                      outlined
                      dense
                      v-model="ameb_unique_number.gender">
                  </v-select>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                class="cancel-btn"
                text
                x-large
                @click="dialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
                color="btn btn-primary"
                dark
                x-large
                @click="dialog = false"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </v-app>
</template>
<script>

export default {
  data() {
    return{
      dialog:false,
      gender: ['Male','Female','Not Specified'],
      ameb_unique_number:{
        first_name:'',
        middle_name:'',
        last_name:'',
        dob:'',
        gender:'',
      }
    }
  },
  methods: {
    closeDialog(){
      this.dialog=false;
    },
    openDialog(){
      this.dialog=true;
    },
    createUniqueNumber(){
      this.resetForm();
      this.openDialog();
    },
    editUniqueNumber(){
      this.openDialog();
      this.ameb_unique_number={
        first_name:'Saurav',
        middle_name:'Kumar',
        last_name:'Basnet',
        dob:'2000-01-12',
        gender:'Male',
      }
    },
    resetForm(){
      this.openDialog();
      this.ameb_unique_number={
        first_name:'',
        middle_name:'',
        last_name:'',
        dob:'',
        gender:'',
      }
    },


  }
}
</script>
